import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Home from './pages/home/home';
import CssBaseline from '@mui/material/CssBaseline';
import BlockInfo from './pages/blockInfo/blockInfo';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/blockinfo' element={<BlockInfo />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
