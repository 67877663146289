import React, { useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo512.png';
  
export default function Header(){

const [anchorElDiscover, setAnchorElDiscover] = useState(null);
const [anchorElReadMore, setAnchorElReadMore] = useState(null);
const [anchorElJoinUs, setAnchorElJoinUs] = useState(null);
const navigate = useNavigate();
  const openMenu = (event, menuSetter) => {
    menuSetter(event.currentTarget);
  };

  const closeMenu = (menuSetter) => {
        menuSetter(null);
    }
  return (
    <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static">
      <Toolbar>
        {/* Logo */}
        <Box sx={{ mr: 2 }}>
          <img src={logo} alt="Logo" style={{ height: '64px' }} />
        </Box>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Athena
        </Typography>
        {/* Centered navigation links with dropdowns */}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button color="inherit" component={Link} to="/">Home</Button>
          <Button 
          color="inherit" 
          endIcon={<ArrowDropDownIcon />} 
          onClick={(e) => openMenu(e, setAnchorElDiscover)}
          >
            Discover
          </Button>
          <Menu
            id="discover-menu"
            anchorEl={anchorElDiscover}
            open={Boolean(anchorElDiscover)}
            onClose={()=>{closeMenu(setAnchorElDiscover)}}
          >
            <MenuItem onClick={() => closeMenu(setAnchorElDiscover)}>The Project</MenuItem>
            <MenuItem onClick={() => closeMenu(setAnchorElDiscover)}>Ecosystem</MenuItem>
            <MenuItem onClick={() => {
                closeMenu(setAnchorElDiscover);
                navigate('/blockinfo');
                }}>
            Block Information
            </MenuItem>
          </Menu>

          <Button 
            color="inherit" 
            endIcon={<ArrowDropDownIcon />} 
            onClick={(e) => openMenu(e, setAnchorElReadMore)}
            >
            Read More
          </Button>
          <Menu
            id="read-more-menu"
            anchorEl={anchorElReadMore}
            open={Boolean(anchorElReadMore)}
            onClose={()=>{closeMenu(setAnchorElReadMore)}}
          >
          <MenuItem onClick={() => closeMenu(setAnchorElReadMore)}>Road Map</MenuItem>
          <MenuItem onClick={() => closeMenu(setAnchorElReadMore)}>Smart Contract</MenuItem>
          </Menu>
          <Button color="inherit">WhitePaper</Button>
          <Button 
            color="inherit" 
            endIcon={<ArrowDropDownIcon />} 
            onClick={(e) => openMenu(e, setAnchorElJoinUs)}
            
            >
            Join Us
          </Button>
          <Menu
            id="join-us-menu"
            anchorEl={anchorElJoinUs}
            open={Boolean(anchorElJoinUs)}
            onClose={()=>{closeMenu(setAnchorElJoinUs)}}
          >
            <MenuItem onClick={() => closeMenu(setAnchorElJoinUs)}>Twitter</MenuItem>
            <MenuItem onClick={() => closeMenu(setAnchorElJoinUs)}>Discord</MenuItem>
          </Menu>
        </Box>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, visibility: 'hidden' }}>
          LOGO
        </Typography>
      </Toolbar>
    </AppBar>
  </Box>
  );
}