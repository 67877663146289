import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { Card, CardContent, Typography, List, ListItem, ListItemText } from '@mui/material';
import Header from '../../components/header/header';

export default function BlockInfo() {
  const [block, setBlock] = useState(null);

  useEffect(() => {
    const fetchBlockNumber = async () => {
      try {
        const web3 = new Web3(process.env.REACT_APP_INFURA_URL);
        const latestBlockNumber = await web3.eth.getBlockNumber();
        const block = await web3.eth.getBlock(latestBlockNumber);
        setBlock(block);
      } catch (error) {
        console.error('Error fetching block:', error);
      }
    };
    
    fetchBlockNumber();
    const intervalId = setInterval(fetchBlockNumber, 10000);

    // 清除定时器
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <Header />
      <Card variant="outlined" sx={{ maxWidth: 800, margin: '20px auto', padding: '20px' }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Latest Ethereum Block Information
          </Typography>
          {block ? (
            <List>
                <ListItem>
                <ListItemText primary="Block Number" secondary={block.number.toString()} />
                </ListItem>
                <ListItem>
                <ListItemText 
                    primary="Timestamp" 
                    secondary={new Date(Number(block.timestamp) * 1000).toLocaleString()} 
                />
                </ListItem>
                <ListItem>
                <ListItemText primary="Transactions" secondary={block.transactions.length.toString()} />
                </ListItem>
                <ListItem>
                <ListItemText primary="Miner" secondary={block.miner.toString()} />
                </ListItem>
                {/* <ListItem>
                <ListItemText primary="Difficulty" secondary={block.difficulty.toString()} />
                </ListItem>
                <ListItem>
                <ListItemText primary="Total Difficulty" secondary={block.totalDifficulty.toString()} />
                </ListItem> */}
                <ListItem>
                <ListItemText primary="Size" secondary={`${block.size.toString()} bytes`} />
                </ListItem>
                <ListItem>  
                <ListItemText primary="Gas Used" secondary={block.gasUsed.toString()} />
                </ListItem>
                <ListItem>
                <ListItemText primary="Gas Limit" secondary={block.gasLimit.toString()} />
                </ListItem>
                <ListItem>
                <ListItemText primary="Hash" secondary={block.hash.toString()} />
                </ListItem>
                <ListItem>
                <ListItemText primary="Parent Hash" secondary={block.parentHash.toString()} />
                </ListItem>
            </List>
          ) : (
            <Typography variant="body2">
              Loading block information...
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
