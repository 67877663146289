import React from 'react';
import Header from '../../components/header/header';
import { Container, Typography, Button, Box } from '@mui/material';
import logo from '../../assets/logo512.png';
import backgroundImage from '../../assets/bg.png';
export default function Home() {

  return (
    <div className='body'>
      <Header />
      <Box
        sx={{
          position: 'relative',
          py: 8, // padding top and bottom
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          color: 'white',
          textAlign: 'center',
          minHeight: '100vh', // 确保至少有100%视口高度
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <img src={logo} alt="Logo" style={{ height: '256px' }}/>
          <Typography component="h1" variant="h2" gutterBottom>
            Athena Coin Hub
          </Typography>
          <Typography variant="h5" paragraph>
            Building the fastest and most decentralized payment network
          </Typography>
          <Button variant="contained" color="primary" sx={{ mt: 4 }}>
            Read The WhitePaper
          </Button>
        </Container>
      </Box>
    </div>
  );
}
